/*
* demo.css
* File include item demo only specific css only
******************************************************************************/

.menu .app-brand.demo {
  height: 64px;
  margin-top: 12px;
}

.app-brand-logo.demo svg {
  width: 22px;
  height: 38px;
}

.app-brand-text.demo {
  font-size: 1.75rem;
  letter-spacing: -0.5px;
  text-transform: lowercase;
}

/* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
/* Detached navbar */
.layout-navbar-fixed .layout-wrapper:not(.layout-horizontal):not(.layout-without-menu) .layout-page {
  padding-top: 76px !important;
}
/* Default navbar */
.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
  padding-top: 64px !important;
}

/* Navbar page z-index issue solution */
.content-wrapper .navbar {
  z-index: auto;
}

.inctive_red{
  color: red;
}
.All_time_user{
  color: #697a8d;
}
.menu-item .active{
  background-color: #0bb81e !important;
  color: white !important;
}
/* .menu-item .active{
  background-color: #0bb81e !important;
  color: white !important;
}
.text-primary {
  color: #0bb81e !important;
}
.btn-primary {
  color: #fff;
  background-color: #0bb81e !important;
  border-color: #0bb81e !important;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(11 184 30 / 63%) !important;
}

.text-primary {
  color: #0bb81e !important;
} */


.icon_user_color{
  background: lightblue;
  padding: 6px 9px;
  border-radius: 4px;
}
.user_name_add{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown_menu{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.ed_cls_edite1{
  background: green;
  padding: 5px 8px;
  color: #ededf9;
  border-radius: 5px;
}
.ed_cls_edite2{
  background: red;
  padding: 5px 8px;
  color: #ededf9;
  border-radius: 5px;
}
.button_css_add_cancle{
  text-align: end;
}
.save_bar{
  margin-right: 14px;
}
.combo_bar_dom .nav-item .nav-link .active{
  background-color: #696cff !important;
  color: white !important;
}
.ball_icon{
  margin-right: 3px;
}
.user_list_add{
  color: white;
  font-weight: 500;
}
.user_list_add:hover{
  color: white;
}
.add_user_btn_bar{
  margin-right: 22px;
}
.card_hed_user{
  padding-left: 32px !important;
}
.td_head_name{
  padding-left: 37px !important;
}
.td_head_name_department{
  padding-left: 32px !important;
}
.departprt_head{
  padding-left: 31px !important;
}
.Edit_user_user_list{
  color: white;
}
.Edit_user_user_list:hover{
  color: white;
}
.card_wredb{
  padding-bottom: 31px !important;
    padding-left: 14px !important;
}
.logo_img_for_side_bar{
  z-index: 9999999;
  position: absolute;
  width: 100%;
  top: 1px;
  max-width: 140px;
  left: 34px;
}
.footer_logo_img{
  width: 100%;
    max-width: 250px;
}
.add_deletebutton{
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.form_selected_for_custom{
  padding: 6px 11px !important;
}
.faa_chirvon_top{
  padding-top: 5px;
  padding-left: 6px;
}
.bell_icon{
  color: black;
  font-size: 18px;
  font-weight: 900;
}
.sup_rep{
  background: red;
  padding: 4px 8px;
  color: white;
  border-radius: 50%;
}
.logo_bar_team{
  width: 100%;
  max-width: 200px;
}
.link_to_desboard{
  color: white;
}
.link_to_desboard:hover{
  color: white;
}
.fb_dashboard_department{
  font-size: 23px;
    padding-top: 20px;
    padding-bottom: 10px;
}
/*
* Content
******************************************************************************/

.demo-blocks > * {
  display: block !important;
}

.demo-inline-spacing > * {
  margin: 1rem 0.375rem 0 0 !important;
}

/* ? .demo-vertical-spacing class is used to have vertical margins between elements. To remove margin-top from the first-child, use .demo-only-element class with .demo-vertical-spacing class. For example, we have used this class in forms-input-groups.html file. */
.demo-vertical-spacing > * {
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-lg > * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-lg.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-xl > * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-xl.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir='rtl'] .rtl-only {
  display: block !important;
}

/*
* Layout demo
******************************************************************************/

.layout-demo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1rem;
}
.layout-demo-placeholder img {
  width: 900px;
}
.layout-demo-info {
  text-align: center;
  margin-top: 1rem;
}
