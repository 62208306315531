.Toastify__toast
{
    font-weight: 400;
    font-family: "Nunito", "Segoe UI", arial !important;
    font-size: 14px;
}
.account_bar
{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.w-200
{
    width:200px;
}
.w-120
{
    width: 120px;
}

.w-300
{
    width: 300px;
}


.w-150
{
    width: 150px;
}

.table-dark th
{
    color:white !important;
}
.custom table {
    border-collapse: collapse;
 }
 
 /* And this to your table's `td` elements. */
 .custom table td {
    padding: 2px 10px !important; 
    margin: 0px !important;
 }
 .custom table th {
    padding-left:10px !important; 
   
 }

 .eventviewdetails
 {
    background: #666565bf !important;
    color: white !important;
 }
 


.orderviewtble thead
{
    background-color: #f1f1f1 !important;
}

.orderviewtble.table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
}

.orderviewtble.table.table-bordered td, .orderviewtble.table.table-bordered th {
    border-color: #666666;
}
.vieweventtitle
{
    background: black !important;
    color: white !important; 
}
.MyThreeDots
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.bgeventdatecol
{
    background: #edf8eb !important;
}
.eventdate
{
  
    border-radius: 5px;
    padding: 5px;
    /* text-align: center; */
    font-size: 14px;
    /* width: 100px;
    height: 100px; */
    
   
}

.eventtiime
{
    color:  #233446;;
    font-size: 18px;
    font-family: 'Luckiest Guy', cursive;
}
.edate
{
    color: black;
    font-weight: 800;
}

.customflexcol
{
    display: flex;
    column-gap: 15px;
}
.selectservicewidth
{
    width: 160px;
}
.thumb-pic
{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid rgb(0 0 0);
    box-shadow: 0px 0px 2px 0px #444141;
    transition: transform 0.3s ease;
}

.thumb-pic:hover
{
    display: inline-block;
    transform: scale(3) rotate3d(1, 1, 0, 10deg); 
}

.profileboxViewer
{
    position: relative;
    display: inline-block;
}

.removeProfileImg {
    position: absolute;
    right: -8px;
    top: -8px;
    font-size: 18px;
    color: red;
    font-weight: 600;
    cursor: pointer;
}

.view-thumb-pic
{
    width: 100%;
    display: block;
}

.fieldLabel
{
    font-weight: 500;
}

.infodetailsfield
{
    display: flex;
    column-gap: 20px;
    margin-bottom: 10px;
}
.servicesAttachments
{
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
}
.servicesAttachments .img-box-attach
{
    width: 50px;
    aspect-ratio: 1/1;
    transition: 500ms;
}

.servicesAttachments .img-box-attach:hover
{
    transform: scale(3) rotate3d(1, 1, 0, 10deg); 
}
.colorAttachmentslabel
{
    color: #ff8b77;
}
.addservicestable th , .addservicestable td 
{
    vertical-align: top !important;
}

.sup_rep
{
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    position: absolute;
    text-align: center;
    padding: 0px !important;
    font-size: 12px;
}
.github-button
{
    position: relative;
    padding: 10px 23px !important;
    display: inline-block;
}

.sename
{
    font-size: 14px;
}
.sname
{
    font-size: 20px;
}

.sename 
{
    color: #000000;
}
.sename b
{
    color: #198754;
}
.customcardbg
{
    background:#0bb81e1f !important;
    color: white;
}

.custom-avatar
{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eefbe7;
    color: #71dd37;
    border-radius: 5px;
}
.custom-avatar i
{
    margin: 0px;
    padding: 0px;
}
.avatar img
{
    aspect-ratio: 1;
}
/* .taskpending
{
    background-color: #ff00003d !important;
}

.taskcompleted
{
    background-color: #0bb81e5c  !important;
} */

.taskpending .card-body
{
    background-color: transparent;
}
.taskcompleted .event-name-task a
{
    color: green;
}

.taskpending .event-name-task a
{
    color: #ff4828;
}

.taskcard
{
    background: #0bb81e1c !important;
}

.custom-btn-success {
    color: #fff;
    background-color: #0bb81e !important;
    border-color: #0bb81e !important;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(113, 221, 55, 0.4);
}
.table-responsive td
{
    vertical-align: baseline;
}
.menu_item_bar {
    width: 350px !important;
    overflow-y: scroll !important;
    height: auto !important;
}
.dropdown-item
{
    white-space: normal !important;
}

.notificationlistitemread 
{
    background-color: #f4f4f4  !important;
}

.notificationlistitemunread 
{
    background-color: #d5d5d5  !important;
}



.notificationlistitem a
{
    color: #21201f !important;
    font-weight: 600;
}

.layout-wrapper:not(.layout-horizontal) .bg-menu-theme .menu-inner > .menu-item a.active:before {
    content: "";
    position: absolute;
    right: -15px;
    width: 0.25rem;
    height: 2.6845rem;
    border-radius: 0.375rem 0 0 0.375rem;
    background: #0bb81e;
}

.bg-menu-theme .menu-inner > .menu-item.active > .menu-link {
    color: #0bb81e;
    background-color: rgb(11 184 30 / 25%) !important;
}

.dropdown-item:not(.disabled).active, .dropdown-item:not(.disabled):active {
    background-color: rgb(11 184 30 / 12%);
    color: #0bb81e !important;
}

.dropdown-item{
  
    display: flex  !important;
    align-items: center !important;
    column-gap: 1px  !important;
}

.dropdown-item:not(.disabled).active, .dropdown-item:not(.disabled):active {
    background-color: rgb(11 184 30 / 12%);
    color: #0bb81e !important;
  
}

.FullpageLoader
{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99999999999;
    width: 100%;
    height: 100%;
    background-color: #ffffffb5;
    overflow: hidden;
}

.taskpendingrow
{
    background-color: #ff3e1d0a;
}


.taskcompletedrow
{
    background-color: #0bb81e0a;
}
.wmin-700
{
    width:600px;
}
.fontbold
{
    font-weight: 500;
}
.bgcustomlabel
{
    background-color: #058204c4 !important;
    color: #ffffff !important;
}
.customflex
{
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
}
.customflex select
{
    width: 80%;
}
.customflex button
{
  
    display:block;
    width: 20%;
}
.btn.disabled, .btn:disabled, fieldset:disabled .btn
{
    background-color: #0bb81e !important;
    border-color: #0bb81e !important;
}
.servicesfiilter
{
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.inline-block
{
    display: inline-block;
}
.filterAttendanceRow .form-control
{
    font-size: 14px !important;
    font-weight: 600 !important;
}
.hoursCol
{
    background-color: #50b058 !important;
    color: white;
}

.timimgcol
{
    background: #b5d1d7 !important;
    color: black;
}
.endstarttimimgcol
{
    background: #f4f4cf !important;
    color: black;
}

.empnamecol
{
    background: #ecedff !important;
    color: black;
}
.absent
{
    color: white !important;
    background-color: #444444 !important;
}

.colorboxitems
{
    padding:0px;
    margin: 0px;
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.colorboxitems li
{
        display: flex;
        align-items: center;
        column-gap: 10px;
}
.colorboxitems li span.colorbox
{
      
       display: inline-block;
       width: 20px;
       height: 20px;
}
.colorboxitems li span.colorboxstarttime
{
    background: #b5d1d7 ;
}
.colorboxitems li span.colorboxendtime
{
    background: #f4f4cf  ;
}
.colorboxitems li span.colorboxtotalhourse
{
    background: #50b058  ;
}

.colorboxlabel
{
    font-size: 13px;
}

.float-rights
{
    float: right;
}

.fromtimewidth
{
    width: 80px;
}

.totimewidth
{
    width: 80px;
}
.wth-200
{
    width: 280px;
}

.cursor-pointer{
    cursor: pointer;
  }

  .flex_top_wrep
  {
    width: 100%;
  }

  div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #0099ffc4 !important; 
    color: #fff;
    font-size: 1em;
}
.fc-scrollgrid-sync-table
{
    width: 100% !important;
}

.eventboxleft
{
    background-color:#d9f0db;
    padding:15px;
    height: 100%;
    border-radius: 5px;
    transition: 600ms;
}

.eventboxleft:hover
{
    background-color:#191b19;
    color: white !important;
    transform: scale(1.2);
}


.eventboxleft:hover .eventboxcontent-label
{
  
    color: white !important;
} 

.eventboxleft:hover .eventboxcontent-data
{
 
    color: white !important;
} 
.eventboxleft:hover .eventboxicon
{
    box-shadow: 0px 0px 20px 8px rgb(97 115 94 / 80%);
}




.eventboxleftlistitembox
{
    padding: 0px;
    list-style-type: none;
}

.eventboxleftlistitembox li
{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #0bb81e;
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.eventboxleftlistitembox li .eventboxicon
{
    color: #0bb81e;
    font-size: 25px;
    width: 50px;
    height: 50px;
    background: #0bb81e63;
    text-align: center;
    line-height: 50px;
    border-radius:50%;
    margin: auto;

}

.eventboxleftlistitembox li .eventboxicon i
{
    padding: 0px;
    margin: 0px;
}


.eventboxleftlistitembox li .eventboxcontent .eventboxcontent-label
{
    color: #232323;
    font-size: 15px;
    display: block;
    font-weight: 700;
    transition: 600ms;
}

.eventboxleftlistitembox li .eventboxcontent .eventboxcontent-data
{
    color: #232323;
    font-size: 17px;
    display: block;
    font-weight: 400;
    transition: 600ms;
}


/* td.stickycol 
{
    position: sticky;
    left: 0px;
    z-index: 99999;
    background-color: rgb(255, 255, 255) !important;
   
}

th.stickycol
{   
    position: sticky;
    left: 0px;
    z-index: 99999;
    
}
td.stickycol1
{
    position: sticky;
    left: 70px;
    z-index: 99999;
    background-color: rgb(255, 255, 255) !important;
   
}
th.stickycol1 
{
    position: sticky;
    left: 70px;
    z-index: 99999; 

}

td.stickycol3
{
    position: sticky;
    right: 0px;
    z-index: 99999;
    background-color: rgb(255, 255, 255) !important;
   
}
th.stickycol3 
{
    position: sticky;
    right: 0px;
    z-index: 99999; 

} */

